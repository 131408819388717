@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'quill/dist/quill.snow.css';

@layer base {
  @font-face {
    font-family: 'Glyddon';
    src: url('./assets/fonts/Glyddon-Bold.otf') format('opentype');
    font-weight: 700;
    font-display: swap;
  }

  html {
    font-size: 16px;
    font-family: 'Carlito', sans-serif;
    @apply text-black;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:not([type='radio']),
select,
textarea {
  @apply px-3 py-2 shadow-lg w-full rounded-md min-h-12 border border-slate-50;
}

input:disabled {
  @apply bg-gray-light border-gray-light;
}

.form-error {
  @apply text-red text-xs text-left m-2;
}

:focus-visible {
  @apply outline-brown-light;
}

table th,
table td {
  @apply p-3;
}

.text-26 {
  font-size: 26px;
  line-height: 32px;
}

.template-form-container {
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
}

/* Quill Override CSS */
.ql-toolbar {
  @apply rounded-t-md bg-gray-light;
}
.ql-toolbar.ql-snow {
  font-family: 'Carlito';
  @apply py-3 border-gray-argent border-b-0;
}
.ql-container {
  @apply bg-gray-light pb-8 px-4 rounded-b-md;
}
.ql-container.ql-snow {
  @apply border-gray-argent;
}
.ql-editor {
  @apply bg-white min-h-[300px];
}
.ql-snow .ql-picker.ql-font {
  width: 120px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 30px;
}
.ql-toolbar ::before {
  @apply font-bold;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  @apply text-gray-argent;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Carlito (Default)';
}
.ql-toolbar .ql-font .ql-picker-item[data-value='Glyddon']::before,
.ql-toolbar .ql-font .ql-picker-label[data-value='Glyddon']::before {
  content: 'Glyddon';
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '16  (Default)';
}
.ql-toolbar .ql-size .ql-picker-item[data-value='14px']::before,
.ql-toolbar .ql-size .ql-picker-label[data-value='14px']::before {
  content: '14';
}

.ql-toolbar .ql-size .ql-picker-item[data-value='18px']::before,
.ql-toolbar .ql-size .ql-picker-label[data-value='18px']::before {
  content: '18';
}
.ql-toolbar .ql-size .ql-picker-item[data-value='20px']::before,
.ql-toolbar .ql-size .ql-picker-label[data-value='20px']::before {
  content: '20';
}

.ql-toolbar .ql-size .ql-picker-item[data-value='24px']::before,
.ql-toolbar .ql-size .ql-picker-label[data-value='24px']::before {
  content: '24';
}
